.rbc-event-label {
  display: none;
}

.rbc-event{
   /* border-radius: 4px !important;  */
   border: solid 2px #fff !important; 
}


.store_calendar .rbc-row-content {

  height: 100px;
  overflow-y: auto;
  
}
.store_calendar .rbc-row-content::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}
.store_calendar .rbc-time-content::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}

.store_calendar .rbc-label{
  min-width:0px !important;
  width:0px !important;
  padding: 0px;
  border-right: 0px;
}

.store_calendar .rbc-time-header.rbc-overflowing{
  width:100%;
}

.store_calendar .no_tenpo .rbc-row-content{
  height:40px;
}

/* .staff_calendar .rbc-time-gutter.rbc-time-column{
  display:none;
} */



.cl .rbc-allday-cell {

  height: 100px;
  overflow-y: auto;

}

.cl .rbc-allday-cell::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}


.cl .rbc-time-header{
  margin-right: 0 !important;
  /* height:50px !important; */
}

.cl .rbc-time-content::-webkit-scrollbar {  /* Chrome, Safari 対応 */
  display:none;
}


.cl.no_tenpo .rbc-time-header {
  height:60px !important;
}





.koukyu .rbc-events-container{
  background-color:#e7e5e5;
}
.koukyu .rbc-row-content{
  background-color:#e7e5e5;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
} */


.todaylist .icon1{
  color: #4C4C4C;      
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 0px;
  padding: 7px 7px;
  border-radius: 2px;
  box-sizing: content-box;
  background-color: #98fb98;
  border :1px solid #98fb98;
}

.todaylist .icon2{
  color: #4C4C4C;      
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 0px;
  padding: 7px;
  border-radius: 2px;
  box-sizing: content-box;
  background-color: #F7A46B;
  border :1px solid #F7A46B;
}

.todaylist .icon3{
  color: gray;      
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 0px;
  padding: 7px;
  border-radius: 2px;
  box-sizing: content-box;
  background-color: white;
  border: 1px solid #E5E5E5;
}


.search_calendar .MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root{
  width: 168px !important;
  margin: 0 !important;
}

.search_calendar .MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-hiddenDaySpacingFiller.css-188yni7-MuiPickersDay-root{
  width: 22px !important;
  height: 22px !important;
  margin: 0px !important;
}

.search_calendar .css-1fdf4qj-MuiButtonBase-root-MuiPickersDay-root{
  width: 22px !important;
  height: 22px !important;
  margin: 0px !important;
}
.search_calendar .css-aiowg3-MuiButtonBase-root-MuiPickersDay-root{
  width: 22px !important;
  height: 22px !important;
  margin: 0px !important;
}
.search_calendar .css-du5qke-MuiTypography-root{
  height: 20px !important;
}

.search_calendar .css-1l3etzp{
  height: 160px;
  min-height: 160px !important;
}

.search_calendar .css-18puooo
{
  width: 0px !important;
}
.search_calendar .css-1v994a0
{
  width: 30px !important;
}
.search_calendar .css-1dozdou
{
  padding-left: 10px !important;
  margin:0px !important;
}
/* .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-aiowg3-MuiButtonBase-root-MuiPickersDay-root{

  width: 25px !important;
} */